import React from 'react'
import Link from 'gatsby-link'
import Block from "../components/Block"
import "../css/style.scss"

const NotFoundPage = () => (
  <Block color="colorCoral"
    left={
      <div className="grid__item space--bottom">
        <h1>404 Error</h1>
        <p className="space--bottom-none">NICHT GEFUNDEN</p>
        <p>PÁGINA NO ENCONTRADA</p>
        <Link className="btn btn--white" to="/">Home</Link>
      </div>
    }
  />
)

export default NotFoundPage
